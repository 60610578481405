import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  Avatar,
  Flex,
  Stack,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { LandingRight } from "../Components/LandingRight";
import { SpaceLikes } from "../Components/spaceLike";
import { IoIosArrowForward } from "react-icons/io";
import ShareModel from "../Components/Share";
import { CuratedCollectionCard } from "../Components/Collection/CuratedCollectionCard";
import {Helmet, HelmetProvider} from "react-helmet-async";

export const CuratedCollectionsSinglePage = () => {
  let { slug } = useParams();
  const [pageName, setPageName] = useState("");
  const [cat, setcat] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [loading, setloading] = useState(true);
  let [data, setdata] = useState([]);
  const { colorMode, toggleColorMode } = useColorMode();

  let [page, setPage] = useState(1);
  //console.log("page: ", page);
  // const getData = ({ id }) => {
  //   return axios.get(`${process.env.REACT_APP_API}/myspace/public/get/${id}`);
  // };

  let url = `${process.env.REACT_APP_PUBLIC_URL}/collection/curated-collection/${data[0]?.spaceID.slug}`;

  // useEffect(() => {
  //     getData({ id }).then((res) => {
  //       // setdata(res.data.data);
  //       setloading(false);
  //       loadMoreData();
  
  //     });
    
  // }, [id]);

  const [metaTitle, setMetaTitle] = useState('Default Title - AI Zones'); // Use state for metaTitle
  const [metaDescription, setMetaDescription] = useState('Explore a hand-picked collection of AI tools designed to enhance your workflow. Improve efficiency, boost creativity, and achieve your goals with the power of AI.'); // Use state for metaDescription

  useEffect(() => {
    if (data.length > 0 && data[0]?.spaceID?.space_name) {
      const spaceDescription = data[0]?.spaceID.description;
      const defaultDescription = "Explore a hand-picked collection of AI tools designed to enhance your workflow. Improve efficiency, boost creativity, and achieve your goals with the power of AI.";

      const title = `${data[0].spaceID.space_name} - Curated AI Tools Collection - AI Zones`;
      setMetaTitle(title);

      let description = defaultDescription;
      if (spaceDescription && spaceDescription.trim() !== '') {
        description = `${spaceDescription} - ${defaultDescription}`;
      }
      setMetaDescription(description);
    }
  }, [data]);

  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100

        
      ) {
        // console.log("befor")
        // Load more data when the user is near the bottom of the page
        // loadMoreData();
        setPage(page + 1)
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() =>{
    loadMoreData(slug, page)
  }, [slug, page])

  const loadMoreData = async (slug, page) => {
    console.log("page", page)
    console.log("hello world")
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/myspace/public/get/${slug}?page=${page}`
    );
    setloading(false);
    setdata((prev) => [...prev, ...data?.data]);
    // setdata(data?.data)
    // console.log("data.data: ", data.data);
  };

  return (

    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection/curated-collection/${slug}`} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= '../Utils/AI-Tools-Collections-Coverimg-AIZones.png' />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/collection/curated-collection/${slug}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection/curated-collection/${slug}`} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content='../Utils/AI-Tools-Collections-Coverimg-AIZones.png' />


    </Helmet>
    <Box bg={useColorModeValue("var(--landing-page, #FFF)", "#222222")}>
      <Flex marginTop={{ base: "60px", md: "60px" }}>
        <Stack justifyContent="center" w="100%">
          <Box width="90%" m="auto" mt="0px">
            <Flex
              width={"100%"}
              mt="13px"
              mb="25px"
              flexWrap={"wrap"}
              alignItems="center"
              fontSize="12px"
              fontWeight="400"
              lineHeight="20px"
            >
              <Link to="/">
                <Text>Home</Text>
              </Link>

              <Text>
                {" "}
                <IoIosArrowForward
                  size={15}
                  color={colorMode === "light" ? "#1a202c" : "white"}
                />{" "}
                {""}
              </Text>

              <Link to="/collection">
                <Text>Collection</Text>
              </Link>

              <Text>
                {" "}
                <IoIosArrowForward
                  size={15}
                  color={colorMode === "light" ? "#1a202c" : "white"}
                />{" "}
                {""}
              </Text>

              <Link to="/collection">
                <Text>Curated Collections</Text>
              </Link>

              {/* <Text>
                {" "}
                <IoIosArrowForward
                  size={15}
                  color={colorMode === "light" ? "#1a202c" : "white"}
                />{" "}
                {""}
              </Text>

              <Text>{data[0]?.spaceID.space_name}</Text> */}
            </Flex>

            <Flex
              mt="30px"
              // justifyContent="space-between"
              alignItems={"center"}
            >
              <Text as="h1" fontSize="20px" fontWeight="600" >
                {data[0]?.spaceID.space_name}
              </Text>

              
            </Flex>

            <Box
              borderBottom="1px"
              borderColor={useColorModeValue("#e0e0e0", "#444")}
              py={1}
              paddingBottom="20px"
            >
              <Flex mt="5px" justifyContent="space-between" alignItems="center" >
                <Flex gap="5px" alignItems="center" >
                  <Box paddingRight={"5px"} borderRight={"2px solid gray"}>
                    <Text fontSize={"14px"}>Created by</Text>
                  </Box>
                  {/* <Avatar size="xs" src={data[0]?.userID.image} /> */}
                  <Image boxSize="24px"  borderRadius="full" loading="lazy" alt={"Profile picture"}   src={data[0]?.userID?.image } />
                  <Text fontSize={"14px"}>{data[0]?.userID.name}</Text>
                  <Box paddingLeft={"5px"} borderLeft={"2px solid gray"}>
                    <Text fontSize={"15px"} fontWeight={600} color={"#3b89b6"}>
                      {data[0]?.spaceID.tool.length} tools
                    </Text>
                  </Box> 
                  </Flex>
                  <SpaceLikes el={data[0]?.spaceID} />
                
              </Flex>
              <Text   
              as="h2"            
                py="30px"
                fontSize="15px"
                // lineHeight="24px"
                fontWeight="400"
                textAlign="left"
                color={useColorModeValue("gray.600", "gray.400")}
              >
                {data[0]?.spaceID.description}
              </Text>

              <Flex
                w="100%"
                alignItems={"center"}
                justifyContent={"space-between"}
                // marginTop={7}
              >
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  // lineHeight="20px"
                  color="#3B89B6"                 
                >
                  {" "}
                  {data[0]?.spaceID.likes} People have liked this collection
                </Text>
                <ShareModel url={url} ShareText="Share" />
              </Flex>
            </Box>

            <Flex flexDirection={"column"} gap={"20px"} py={"20px"}>
              {data?.map((el, i) => (
                <CuratedCollectionCard
                  el={el}
                  key={i}
                  // el={el.spaceID}
                  setcat={setcat}
                  setPageName={setPageName}
                  setFilterLoader={setFilterLoader}
                />
                // console.log(el)
              ))}
            </Flex>
          </Box>
        </Stack>
          <Box
            sx={{
              display: 'block',
              '@media (max-width: 1500px)': {
                display: 'none',
              },
            }}
          >
            <LandingRight />
          </Box> 
      </Flex>
    </Box>
   </HelmetProvider>
  );
};
