import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  Avatar,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { LandingRight } from "../Components/LandingRight";
import { IoIosArrowForward } from "react-icons/io";
import ShareModel from "../Components/Share";
import { MyCollectionCard } from "../Components/Collection/MyCollectionCard";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CollectionEditButton from "../Components/Collection/CollectionEditButton";
import {Helmet, HelmetProvider} from "react-helmet-async";


export const MyCollectionSinglePage = () => {
  const { slug } = useParams();
  const [pageName, setPageName] = useState("");
  const [cat, setcat] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [loading, setloading] = useState(true);
  const [loop, setLoop] = useState(true);
  let [data, setdata] = useState([]);
  let [dataOther, setdataOther] = useState([]);
  let [latest, setLatestNews] = useState([]);
  // const [page, setPage] = useState(1);
  const { colorMode, toggleColorMode } = useColorMode();
  

  let url = `${process.env.REACT_APP_PUBLIC_URL}/collection/my-collection/${data[0]?.spaceID.slug}`;

  let metaTitle = ''; // Initialize metaTitle outside the useEffect block
  let metaDescription = ''; // Initialize metaDescription outside the useEffect block
  

  useEffect(() => {
    if (data.length > 0 && data[0]?.spaceID?.space_name) {
      const spaceDescription = data[0]?.spaceID.description;
      const defaultDescription =
        "Explore a hand-picked collection of AI tools designed to enhance your workflow. Improve efficiency, boost creativity, and achieve your goals with the power of AI.";

      const metaTitle = `${data[0].spaceID.space_name} - My AI Tools Collection - AI Zones`;
      document.title = metaTitle;

      let metaDescription = defaultDescription;
      if (spaceDescription && spaceDescription.trim() !== '') {
        metaDescription = `${spaceDescription} - ${defaultDescription}`;
      }          
    }
  }, [data]);


  // let dataSet = ()=> {

  //   const getData = (id) => {
  //     return axios.get(`${process.env.REACT_APP_API}/myspace/public/get/${id}?sort=&page=${page+1}`);
  //   };
  //   // Load more data when the user is near the bottom of the page
  //   getData(id).then((res) => {
  //     if (res?.data?.data && res.data.data.length > 0) {
  //       let updateData = [...data, ...res.data.data];
  //       // setdata(res.data.data);
  //       setdataOther(updateData);
  //       console.log("(res.data.data: ",data, res.data.data);
  //       setloading(false);
  //       setdata(dataOther);
        
  //     } 
  //     res.data.data.length === 0 && setLoop(false)
  //   });
  // }
  let page = 0;
  const getLatestNews = async () => {
      page++;
    
      const response = await axios.get(`${process.env.REACT_APP_API}/myspace/public/get/${slug}?sort=&page=${page}`);
      const result = await response;

      setdata((prev) => [...prev, ...result.data.data]);
  
  }

  

  function handleScroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {

      getLatestNews()

    }
  }

  useEffect(() => {
    // dataSetFirst()
    getLatestNews()
  }, [slug])
  useEffect(() => {
    // const getData = (id) => {
    //     return axios.get(`${process.env.REACT_APP_API}/myspace/public/get/${id}?sort=&page=${page}`);
    // };

    // getData(id).then((res) => {
    //   setdata(res.data.data);
    //   //console.log("(res.data.data: ", res.data.data);
    //   setloading(false);
    // });

    
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

  }, []);

  const userData = useSelector((state) => state.userReducer.loginData);

  const handleDeleteCollectionTool = async (id) => {
    const token = userData.data;

    const deleteUrl = `${process.env.REACT_APP_API}/myspace/delete/${id}`;
    try {
      const response = await axios.post(deleteUrl, null, {
        headers: { token },
      });
      toast.success("Successfully deleted!");
      const filterData = data.filter((item) => item._id !== id);
      setdata(filterData);
      // getData({ id }).then((res) => {
      //   setdata(res.data.data);
      //   setloading(false);
      // });
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  // const handleEditCollection = async () =>{
  //   try{

  //   }catch(error){
  //     console.log("error", error);
  //     toast.error("Something went wrong!");
  //   }
  // }
  console.log(data[0]?.spaceID)

 

  return (

    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection/my-collection/${slug}`} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= '../Utils/AI-Tools-Collections-Coverimg-AIZones.png' />
        <link rel="canonical" href= {`${process.env.REACT_APP_PUBLIC_URL}/collection/my-collection/${slug}`} />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${process.env.REACT_APP_PUBLIC_URL}/collection/liked-collection/${slug}`} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content= "../Utils/AI-Tools-Collections-Coverimg-AIZones.png" />

   
    </Helmet>
    <Box bg={useColorModeValue("var(--landing-page, #FFF)", "#222222")}>
      <Flex marginTop={{ base: "60px", md: "60px" }}>
        <Stack justifyContent="center" w="100%">
          <Box width="90%" m="auto" mt="0px">
            <Flex
              width={"100%"}
              mt="13px"
              mb="25px"
              flexWrap={"wrap"}
              alignItems="center"
              fontSize="12px"
              fontWeight="400"
              lineHeight="20px"
            >
              <Link to="/">
                <Text>Home</Text>
              </Link>

              <Text>
                {" "}
                <IoIosArrowForward
                  size={15}
                  color={colorMode === "light" ? "#1a202c" : "white"}
                />{" "}
                {""}
              </Text>

              <Link to="/collection#curatedcollections">
                <Text>Collection</Text>
              </Link>

              <Text>
                {" "}
                <IoIosArrowForward
                  size={15}
                  color={colorMode === "light" ? "#1a202c" : "white"}
                />{" "}
                {""}
              </Text>

              <Link to="/collection#mycollections">
                <Text>My Collections</Text>
              </Link>

              {/* <Text>
                {" "}
                <IoIosArrowForward
                  size={15}
                  color={colorMode === "light" ? "#1a202c" : "white"}
                />{" "}
                {""}
              </Text>

              <Text>{data[0]?.spaceID.space_name}</Text> */}
            </Flex>

            <Flex
              mt="30px"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Text as="h1" fontSize="24px" fontWeight="600">
                {data[0]?.spaceID.space_name}
              </Text>
        {/* /////////////////////////// */}
              {/* <Button
                fontSize={"14px"}
                paddingBlock={"2px"}
                paddingInline={"16px"}
              >
                Edit
              </Button> */}
              <CollectionEditButton slug={slug} name={data[0]?.spaceID?.space_name} description={data[0]?.spaceID?.description} status={data[0]?.spaceID?.status}/>
                 {/* /////////////////////////// */}
            </Flex>

            <Box
              borderBottom="1px"
              borderColor={useColorModeValue("#e0e0e0", "#444")}
              py={1}
              paddingBottom="20px"
            >
              <Flex
                width={"100%"}
                marginTop="10px"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Flex gap="5px" alignItems="center">
                  <Box paddingRight={"5px"} borderRight={"2px solid gray"}>
                    <Text fontSize={"14px"}>Created by</Text>
                  </Box>
                  <Image boxSize="24px"  borderRadius="full" loading="lazy" alt={"Profile picture"}   src={data[0]?.userID?.image } />
                  <Text fontSize={"14px"}>{data[0]?.userID?.name}</Text>
                  <Box paddingLeft={"5px"} borderLeft={"2px solid gray"}>
                    <Text fontSize={"15px"} fontWeight={600} color={"#3b89b6"}>
                      {data[0]?.spaceID?.tool?.length} tools
                    </Text>
                  </Box>
                </Flex>

                <Text fontSize={{ base: "13px", md: "14px" }}>
                  {data[0]?.spaceID?.status}
                </Text>
              </Flex>
              <Text               
                py="30px"
                fontSize="15px"
                // lineHeight="24px"
                fontWeight="400"
                textAlign="left"
                as="h2"
                color={useColorModeValue("gray.600", "gray.400")}
              >
                {data[0]?.spaceID.description}
              </Text>

              <Flex
                w="100%"
                alignItems={"center"}
                justifyContent={"space-between"}
                // marginTop={7}
              >
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  // lineHeight="20px"
                  color="#3B89B6"                 
                >
                  {" "}
                  {data[0]?.spaceID.likes} People have liked this collection
                </Text>
                <ShareModel url={url} ShareText="Share" />
              </Flex>
            </Box>

            <Flex flexDirection={"column"} gap={"20px"} py={"20px"}>
              {data?.map((el, i) => (
                <>
                  <MyCollectionCard
            key={i}
            _id={el._id}
            el={el.videoID}
            setcat={setcat}
            setPageName={setPageName}
            setFilterLoader={setFilterLoader}
            handleDelete={handleDeleteCollectionTool}
            showRemoveButton={data.length > 1}
                  />
                </>
              ))}
            </Flex>
          </Box>
        </Stack>
        <Box>
        <Box
            sx={{
              display: 'block',
              '@media (max-width: 1500px)': {
                display: 'none',
              },
            }}
          >
            <LandingRight />
          </Box> 
        </Box>
      </Flex>
    </Box>
    </HelmetProvider>
  );
};
